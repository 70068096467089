import { TFunction } from 'i18next';
import * as yup from 'yup';

import {
  hasMaxLength,
  hasMinLength,
  isRequired,
  matchLatinChars,
  matchValidStartCharacter,
  matchValidStartCharacterExtended,
  schemaField,
} from '../validation/schemaDefinitions';

import { zipCodeIsRequired } from '@utils/validation';

import { AddressType } from '@typings/graphql';

export const addressLineValidation = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    hasMinLength(2),
    hasMaxLength(75),
    matchValidStartCharacterExtended
  ).when('type', {
    is: addressType => addressType !== AddressType.Localized,
    then: schemaField(t, yup.string(), matchLatinChars),
  });

export const cityValidation = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    matchLatinChars,
    matchValidStartCharacter,
    hasMaxLength(40),
    isRequired
  ).nullable();

export const postalCodeValidation = (t: TFunction) =>
  yup
    .string()
    .when('countryId', {
      is: countryId => zipCodeIsRequired(countryId),
      then: schemaField(
        t,
        yup.string(),
        hasMaxLength(20),
        isRequired,
        matchLatinChars
      ),
      otherwise: schemaField(
        t,
        yup.string(),
        hasMaxLength(20),
        matchLatinChars
      ),
    })
    .nullable();

export const stateValidation = (t: TFunction) =>
  yup
    .string()
    .when('hasStates', {
      is: hasStates => !hasStates,
      then: schemaField(t, yup.string(), hasMaxLength(40), matchLatinChars),
    })
    .nullable();

export const stateIdValidation = (t: TFunction) =>
  yup
    .string()
    .when(['hasStates', 'countryId'], {
      is: (hasStates, countryId) => hasStates && countryId,
      then: schemaField(t, yup.string(), isRequired),
    })
    .nullable();

const addressValidationSchema = (t: TFunction) =>
  yup.object().shape({
    // eslint-disable-next-line react/forbid-prop-types
    states: yup.array(),
    lineOne: addressLineValidation(t)
      .required(
        t('form.address.error_line1_required', 'Address line 1 cannot be empty')
      )
      .nullable(),
    lineTwo: addressLineValidation(t)
      .when('lineThree', {
        is: lineThree => {
          return lineThree && lineThree.length > 0;
        },
        then: yup
          .string()
          .required(
            t(
              'form.address.error_line2_required',
              'Address line 2 cannot be empty'
            )
          ),
      })
      .nullable(),
    lineThree: addressLineValidation(t).nullable(),
    city: cityValidation(t),
    postalCode: postalCodeValidation(t),
    state: stateValidation(t),
    stateId: stateIdValidation(t),
    countryId: yup
      .string()
      .required(t('form.field.error_required', 'Required')),
    internationalProvince: yup
      .string()
      .nullable()
      .when('hasStates', {
        is: hasStates => !hasStates,
        then: schemaField(
          t,
          yup.string(),
          hasMaxLength(40),
          matchLatinChars
        ).nullable(),
      }),
  });

export default addressValidationSchema;
