import React from 'react';

import Flags from 'country-flag-icons/react/3x2';

import { getCountryCode } from '@utils/getCountryCode';

import { Country } from '@typings/graphql';

const FlagLabel: React.FC<{ country: Country }> = ({ country }) => {
  const Flag = Flags[country?.shortCode];

  return (
    <div>
      <span className="mr-3">
        {Flag && (
          <Flag
            title={country.name}
            className="w-3 h-3 inline-block relative bottom-0.5"
          />
        )}
      </span>
      {country.name} +{getCountryCode(country?.shortCode)}
    </div>
  );
};

export default FlagLabel;
