// Various validation functions.

// 1-4 cases for latin letters https://unicode-table.com/en/blocks/
// !?,./0-9 Basic symbols etc.
// a-z Basic Latin
// \u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff Latin-1 Supplement
// \u0100-\u017F Latin Extended-A
// \u0180-\u024F Latin Extended-B
const basicSymbols = '\u0020-\u003F';
const latinBasicRegLine = 'a-z';
const latinSupplementRegLine = '\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff';
const latinExtendedARegLine = '\u0100-\u017F';
const latinExtendedBRegLine = '\u0180-\u024F';
const latinUnderScore = '\u005F';
const latinRegLine = [
  basicSymbols,
  latinBasicRegLine,
  latinSupplementRegLine,
  latinExtendedARegLine,
  latinExtendedBRegLine,
].join('');

const latinAndBasicSymbolsRegLineForEmail = [
  latinRegLine,
  latinUnderScore,
].join('');

export enum NameValidationResult {
  OK,
  INVALID_START,
  TOO_LONG,
  TOO_SHORT,
  NON_LATIN,
}

export enum EmailValidationResult {
  OK,
  INVALID_EMAIL,
  TOO_LONG,
}

export const isValidEmail = (value: string): boolean => {
  // regexp the same as on the DIS3
  const exp =
    "^([a-zA-Z0-9!#$%&'/\\*+=?^_`\\{|\\}~\\-]+\\.)*[a-zA-Z0-9!#$%&'/\\*+=?^_`\\{|\\}~\\-]+@([a-zA-Z0-9]([a-zA-Z0-9~_-]*[a-zA-Z0-9])?\\.)+(?![\\d]+$)[a-zA-Z0-9]([a-zA-Z0-9~_-]*[a-zA-Z0-9])?$";
  const regexp = new RegExp(exp);
  return regexp.test(value);
};

export const isLatinChars = (value: string): boolean => {
  return new RegExp(`^([${latinRegLine}]{0,}]*)$`, 'gi').test(value);
};

export const matchLatinCharsAndIgnoreSign = (value: string): boolean => {
  const regexp = new RegExp(
    `^([${latinAndBasicSymbolsRegLineForEmail}^@]{0,}]*)$`,
    'gi'
  );
  return regexp.test(value);
};

export const validateEmail = () => (value: string): EmailValidationResult => {
  if (!isValidEmail(value)) {
    return EmailValidationResult.INVALID_EMAIL;
  }
  return EmailValidationResult.OK;
};

export const validateName = (
  minLength: number,
  maxLength: number,
  hasLatinCheck: boolean = true
) => (value: string): NameValidationResult => {
  if (hasLatinCheck && !isLatinChars(value)) {
    return NameValidationResult.NON_LATIN;
  }
  if (['.', '-', ' '].filter((c: string) => value.charAt(0) === c).length > 0) {
    return NameValidationResult.INVALID_START;
  }

  if (value.length > maxLength) {
    return NameValidationResult.TOO_LONG;
  }

  if (value.length < minLength) {
    return NameValidationResult.TOO_SHORT;
  }

  return NameValidationResult.OK;
};

const zipCodeCountries: string[] = [
  'USA',
  'CAN',
  'GUM', // Guam.
  'FSM', // Micronesia, Federated States of.
  'PRI', // Puerto Rico.
  'VIR', // United States Virgin Islands.
  'ASM', // American Samoa.
  'MHL', // Marshall Islands.
  'MNP', // Northern Mariana Islands.
  'PLW', // Palau.
];

export const zipCodeIsRequired = (country: string): boolean => {
  return zipCodeCountries.includes(country);
};
