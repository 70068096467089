import React, { useRef, useState } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

import { Button } from '@components/Button';
import PhoneInput from '@components/Formik/PhoneInput';
import { DistrictsSelect } from '@components/Formik/Select/DistrictsSelect';
import TextArea from '@components/Formik/TextArea';
import TextField from '@components/Formik/TextField';
import {
  emailRequiredSchema,
  firstNameSchema,
  lastNameSchema,
  numberRequiredSchema,
  requiredValidation,
} from '@components/Formik/validation/fieldDefinitions';
import Loading from '@components/Loading';
import {
  countryIdValidation,
  extensionValidation,
} from '@presenters/web/pages/Profile/Edit/validationSchema';

import { ContactUsFormClubSelect } from './ContactUsFormClubSelect';
import ContactUsFormCountrySelect from './ContactUsFormCountrySelect';
import ContactUsFormSubjectSelect from './ContactUsFormSubjectSelect';
import ContactUsLoggedInUser from './ContactUsLoggedInUser';

import {
  CONTACT_US_FORM_INITIAL_VALUES,
  ContactUsFormType,
} from '@domain/contact';

import { useRecaptcha } from '@use-cases/account/hook';

import { useUserAccount } from '@repositories/auth/hooks';
import {
  getUserCurrentClubs,
  getUserCurrentDistrict,
} from '@repositories/contact/utils';
import { useDISCountries } from '@repositories/disCountry';

import { getCountryCode } from '@utils/getCountryCode';
import { getLanguageFromURL } from '@utils/query-params';
import { isValidEmail } from '@utils/validation';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { ClubAffiliation } from '@typings/graphql';

const phoneInfoValidationSchema = (t: TFunction) =>
  yup.object().shape({
    countryId: countryIdValidation(t),
    extension: extensionValidation(t),
    number: numberRequiredSchema(t),
  });

const loggedOutUserValidation = (t: TFunction) =>
  yup.object().shape({
    firstName: firstNameSchema(t),
    lastName: lastNameSchema(t),
    email: emailRequiredSchema(t),
    primaryPhone: phoneInfoValidationSchema(t),
    country: requiredValidation(t),
    subject: requiredValidation(t),
    comment: requiredValidation(t),
  });

const loggedInUserValidation = (t: TFunction) =>
  yup.object().shape({
    subject: requiredValidation(t),
    comment: requiredValidation(t),
  });

const validationSchema = (t: TFunction, individualId: Maybe<string>) =>
  individualId ? loggedInUserValidation(t) : loggedOutUserValidation(t);

interface Props {
  handleSubmit: (values: ContactUsFormType, token: Maybe<string>) => void;
}
export const ContactUsForm: React.FC<Props> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const { data } = useDISCountries();
  const recaptchaRef = useRef(null);
  const {
    onRecaptchaChange,
    onRecaptchaExpired,
    resetCaptcha,
    token,
  } = useRecaptcha(recaptchaRef);

  const { user, isProcessingLogin } = useAppConfig();
  const individualId =
    (user?.isLoggedIn && !isProcessingLogin && user?.individualId) || null;
  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useUserAccount(individualId);

  let loggedInUserInfo: ContactUsFormType = CONTACT_US_FORM_INITIAL_VALUES;
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const primaryPhoneLabels = {
    phoneExtension: t('contact.us.form.phone-label-extension', 'Extension'),
    phoneCode: t('contact.us.form.phone-code', 'Phone Code'),
    phoneNumber: t('contact.us.form.phone-label', 'Phone Number'),
  };

  if (individualId) {
    if (userDataLoading || userDataError) {
      <Loading />;
    }
    const individual = userData?.getIndividual;
    const clubsAffiliations =
      (individual?.clubAffiliations as ClubAffiliation[]) || [];

    const club = getUserCurrentClubs(clubsAffiliations);
    const district = getUserCurrentDistrict(clubsAffiliations);

    loggedInUserInfo = {
      firstName: individual?.firstName || '',
      lastName: individual?.lastName || '',
      email: individual?.email || '',
      primaryPhone: {
        number: individual?.primaryPhone?.number || '',
        country: individual?.primaryPhone?.country || '',
        countryId: individual?.primaryPhone?.countryId || '',
        extension: individual?.primaryPhone?.extension || '',
      },
      country: individual?.primaryAddress?.country || '',
      club,
      district,
      subject: '',
      comment: '',
    };
  }

  const handleFormSubmit = async (
    contactForm: ContactUsFormType,
    helpers: FormikHelpers<ContactUsFormType>
  ) => {
    const { subject, comment } = contactForm;
    const contactFormUserInfo = individualId
      ? { ...loggedInUserInfo, subject, comment }
      : { ...contactForm };

    // Get Phone country code
    const country = data?.countries.find(
      country => country.name === contactFormUserInfo.primaryPhone?.country
    );

    const countryId = `+${getCountryCode(country?.shortCode)}`;
    const { primaryPhone: selectedPrimaryPhone } = contactFormUserInfo;
    const primaryPhone = { ...selectedPrimaryPhone, countryId };

    handleSubmit({ ...contactFormUserInfo, primaryPhone }, token);
    helpers.setSubmitting(false);
    resetCaptcha();
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={CONTACT_US_FORM_INITIAL_VALUES}
      validationSchema={validationSchema(t, individualId)}
    >
      {({ isSubmitting, isValid, touched, values }) => {
        const { district } = values;

        const isSubmitButtonDisabled =
          isSubmitting || !isValid || isEmpty(touched);

        const handleBlurEmailHandler = () => {
          if (isEmpty(values.email)) {
            setEmailErrorMessage(t('form.field.error_required', 'Required'));
          } else if (!isValidEmail(values.email)) {
            setEmailErrorMessage(
              t('form.field.error_invalid_email', 'Invalid email address')
            );
          } else {
            setEmailErrorMessage('');
          }
        };

        return (
          <Form>
            {individualId ? (
              <ContactUsLoggedInUser {...loggedInUserInfo} />
            ) : (
              <>
                <TextField
                  name="firstName"
                  label={t('contact.us.form.firstname-label', 'First Name')}
                  required
                />
                <TextField
                  name="lastName"
                  label={t('contact.us.form.lastname-label', 'Last Name')}
                  required
                />
                <TextField
                  name="email"
                  label={t('contact.us.form.email-label', 'Email')}
                  onBlurHandler={handleBlurEmailHandler}
                  required
                  extraClasses={
                    emailErrorMessage ? 'bg-red-100 border-red-600' : ''
                  }
                />
                {emailErrorMessage && (
                  <p className="form-error mb-2 -mt-4 text-xs text-red-600">
                    {emailErrorMessage}
                  </p>
                )}
                <PhoneInput suffix="primaryPhone" labels={primaryPhoneLabels} />
                <ContactUsFormCountrySelect
                  name="country"
                  label={t('contact.us.form.address.country-label', 'Country')}
                  required
                  clearable
                  placeholder={t(
                    'contact.us.form.address.country.placeholder',
                    'Select...'
                  )}
                />
                <DistrictsSelect
                  name="district"
                  label={t(
                    'contact.us.form.filters-district-label',
                    'District'
                  )}
                  clearable
                  placeholder={t(
                    'contact.us.form.address.district.placeholder',
                    'Select...'
                  )}
                />
                <ContactUsFormClubSelect
                  name="club"
                  clearable
                  label={t('contact.us.form.filters-club-label', 'Club Name')}
                  riDistrictId={Number(district)}
                  placeholder={t(
                    'contact.us.form.address.club.placeholder',
                    'Select...'
                  )}
                />
              </>
            )}
            <ContactUsFormSubjectSelect
              name="subject"
              label={t('contact.us.form.address.subject-label', 'Subject')}
              required
              clearable
              placeholder={t(
                'contact.us.form.address.subject-placeholder',
                'Select...'
              )}
            />
            <TextArea
              name="comment"
              areaclasses="h-44 py-4 px-2"
              label={t('contact.us.form.comment.label', 'Comment or question')}
              required
            />
            <ReCAPTCHA
              hl={getLanguageFromURL()}
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
              onChange={onRecaptchaChange}
              onExpired={onRecaptchaExpired}
            />
            <div className="pb-6 pt-6">
              <Button disabled={isSubmitButtonDisabled}>
                {t('contact.us.form.button-label', 'Submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactUsForm;
