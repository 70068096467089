import * as Yup from 'yup';
import { TFunction } from 'i18next';
import {
  firstNameSchema,
  lastNameSchema,
  middleNameSchema,
  localizedNameSchema,
  prefixSchema,
} from '@components/Formik/validation/fieldDefinitions';

const personalNameSchema = (
  t: TFunction,
  isLocalizedNameRequired?: boolean
) => {
  const schema = {
    firstName: firstNameSchema(t),
    middleName: middleNameSchema(t),
    lastName: lastNameSchema(t),
    localizedName: localizedNameSchema(t),
    prefix: prefixSchema(t),
  };

  if (isLocalizedNameRequired) {
    schema.localizedName = localizedNameSchema(t).required(
      t('form.field.error_required', 'Required')
    );
  }

  return Yup.object(schema);
};

export default personalNameSchema;
